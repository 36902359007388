import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/settings/profile',
    name: 'Profile',
    component: () => import('../views/Profile.vue')
  },
  {
    path: '/settings/settings',
    name: 'Settings',
    component: () => import('../views/Settings.vue')
  },
  {
    path: "/settings/campaign/edit/:key?",
    name: "edit-campaign",
    component: () =>
      import("@/components/Settings-Components/subCampaigns/edit-campaign/EditCampaign.vue"),
  },
  {
    path: '/settings/quickstar',
    name: 'Settings',
    component: () => import('../views/QuickStart.vue')
  },
  {
    path: '/settings/home',
    name: 'Settings',
    component: () => import('../views/Home.vue')
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
export default router
